body {
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Product Sans, Helvetica, Arial, sans-serif;
  font-size: 100%;
  font-weight: 300;
  overflow: hidden;
}

.main {
  text-align: center;
  position: relative;
  top: 45%;
  transform: translateY(25%);
}

.avatarContainer {
  filter: drop-shadow(10px 10px 20px #000);
  border-radius: 100px;
  width: max-content;
  height: max-content;
  margin: 0 auto;
}

.avatar {
  pointer-events: all;
  filter: none;
  border-radius: 100px;
  width: 175px;
  height: 175px;
}

.topping > .container-top {
  min-width: 50%;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.summary {
  min-height: 75vh;
}

.summary > h1 {
  filter: drop-shadow(10px 10px 20px #000);
  margin: 0;
  font-size: 650%;
  font-weight: 500;
}

h3 {
  font-size: 250%;
}

.ulshadow {
  filter: blur(12px);
  z-index: 1;
  background: linear-gradient(45deg, #d70000 0%, #4b0000 100%) 0 0 / 200% 100%;
  border-radius: 100px;
  width: 800px;
  height: 175px;
  margin: 0 auto;
  animation: 3s infinite alternate gradient;
  position: relative;
}

.list-group {
  text-align: center;
}

ul {
  z-index: 5;
  background: linear-gradient(45deg, #d70000 0%, #4b0000 100%) 0 0 / 200% 100%;
  border-radius: 100px;
  width: max-content;
  margin: 0 auto;
  padding: 10px;
  animation: 3s infinite alternate gradient;
  position: relative;
  top: -160px;
}

ul li {
  filter: drop-shadow(10px 10px 20px #000);
  padding-left: 25px;
  list-style-type: none;
  display: inline-block;
  position: relative;
  top: 2px;
}

ul li:first-of-type {
  padding-left: 0;
}

ul li img {
  opacity: .8;
  width: 125px;
  transition: all .4s ease-in-out;
  position: relative;
}

ul li img:hover {
  transform: scale(1.025);
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

footer > a > img {
  float: right;
  width: 30px;
  position: relative;
  bottom: 5px;
  right: 5px;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}
/*# sourceMappingURL=index.1fc1e949.css.map */

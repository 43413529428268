body
  overflow hidden
  margin 0
  padding 0
  background-position center
  background-size cover
  background-repeat no-repeat
  color #fff
  font-weight 300
  font-size 100%
  font-family 'Product Sans', Helvetica, Arial, sans-serif
  height 100%

@keyframes gradient
  0%
    background-position 0%

  100%
    background-position 100%

.main
  position relative
  top 45%
  text-align center
  transform translateY(25%)

.avatarContainer
  width max-content
  height max-content
  margin 0 auto
  border-radius 100px
  filter drop-shadow(10px 10px 20px black)

.avatar
  width 175px
  height 175px
  border-radius 100px
  pointer-events all
  filter none

.topping
  & > .container-top
    margin-right auto
    margin-left auto
    min-width 50%
    max-width 75%

.summary
  min-height 75vh

  & > h1
    font-weight 500
    font-size 650%
    margin 0
    filter drop-shadow(10px 10px 20px black)

h3
  font-size 250%

.ulshadow
  background linear-gradient(45deg, #D70000 0%, #4B0000 100%)
  filter blur(12px)
  animation gradient 3s ease infinite
  background-size 200% 100%
  animation-direction alternate
  position relative
  z-index 1
  width 800px
  height 175px
  margin 0 auto
  border-radius 100px

.list-group
  text-align center

ul
  padding 10px
  background linear-gradient(45deg, #D70000 0%, #4B0000 100%)
  animation gradient 3s ease infinite
  background-size 200% 100%
  animation-direction alternate
  border-radius 100px
  margin 0 auto
  width max-content
  z-index 5
  position relative
  top -160px

  li
    display inline-block
    padding-left 25px
    list-style-type none
    top 2px
    position relative
    filter drop-shadow(10px 10px 20px black)

    &:first-of-type
      padding-left 0

    img
      width 125px
      opacity 0.8
      transition all 0.4s ease-in-out
      position relative

      &:hover
        transform scale(1.025)

footer
  position fixed
  bottom 0
  width 100%

  >a>img
    width 30px
    float right
    position relative
    right 5px
    bottom 5px
